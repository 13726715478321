import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { ShiftTemplate } from "../../types/template";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddShiftTemplateMutation,
  useEditShiftTemplateMutation,
  useGetShiftTemplateByIdQuery,
} from "../../services/template.service";
import { useTitle } from "../../hooks/useTitle";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SelectTimezone from "../../components/SelectTimezone";
import { formatWithTimezone } from "../../utils/dateHelper";

const myTimezone = dayjs.tz.guess();

const ShiftTemplateEditPage = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();

  const { data: dataTemplate, isLoading: loadingTemplate } =
    useGetShiftTemplateByIdQuery(parseInt(templateId!), {
      skip: !templateId,
    });
  const [add, { isLoading: adding }] = useAddShiftTemplateMutation();
  const [edit, { isLoading: updating }] = useEditShiftTemplateMutation();

  useTitle(!!dataTemplate ? "Edit Template" : "Create New Shift Template");

  const data = React.useMemo(() => {
    if (!!dataTemplate) return dataTemplate;
    else return null;
  }, [dataTemplate]);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      payload.start_date = dayjs(payload.start_date).format("YYYY-MM-DD HH:mm");
      payload.end_date = dayjs(payload.end_date).format("YYYY-MM-DD HH:mm");

      if (!data) {
        const template: ShiftTemplate = await add(payload).unwrap();
        toast.success("Template created");
        navigate(`/jobs/shift-templates`, { replace: true });
      } else {
        payload.id = data.id;
        await edit(payload).unwrap();
        toast.success("Template updated");
        navigate(`/jobs/shift-templates`, { replace: true });
      }
    } catch (err: any) {
      toast.error(err);
    }
  };

  React.useEffect(() => {
    setValue("name", data?.name || "");
    setValue("description", data?.description || "");
    setValue("confidential_information", data?.confidential_information || "");
    setValue("num_of_talent", data?.num_of_talent || 1);
    setValue("timezone", data?.timezone || myTimezone);
    setValue(
      "start_date",
      dayjs.utc(data?.start_date).tz(data?.timezone) || null
    );
    setValue("end_date", dayjs.utc(data?.end_date).tz(data?.timezone) || null);
  }, [data]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item md={12} lg={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              {loadingTemplate && <CircularProgress />}
              <Card sx={{ mt: 1 }}>
                <CardHeader
                  title="Template Info"
                  titleTypographyProps={{ fontSize: 18, color: "#FF0000" }}
                />
                <CardContent>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Shift Name"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.name}
                        helperText={!!errors.name && "This field is required"}
                      />
                    )}
                  />
                  <Controller
                    name="confidential_information"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Confidential Information (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="num_of_talent"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Number of Talent (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.num_of_talent}
                        helperText={
                          !!errors.num_of_talent && "This field is required"
                        }
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }}>
                <CardHeader
                  titleTypographyProps={{
                    fontSize: 18,
                    color: "#07bc0c",
                  }}
                  title="Working Hour"
                />
                <CardContent>
                  <Box>
                    <Controller
                      name="start_date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DesktopTimePicker
                          sx={{ mt: 1 }}
                          onChange={onChange}
                          value={value}
                          label="Start"
                          format="hh:mm A"
                          onError={() => {
                            setError("start_date", {
                              type: "custom",
                              message: "Invalid",
                            });
                          }}
                          onAccept={() => clearErrors("start_date")}
                          slotProps={{
                            textField: {
                              helperText:
                                !!errors.start_date &&
                                "Please set a valid time",
                            },
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="end_date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DesktopTimePicker
                          sx={{ mt: 1, ml: 1 }}
                          onChange={onChange}
                          value={value}
                          label="End"
                          format="hh:mm A"
                          onError={() => {
                            setError("end_date", {
                              type: "custom",
                              message: "Invalid",
                            });
                          }}
                          onAccept={() => clearErrors("end_date")}
                          slotProps={{
                            textField: {
                              helperText:
                                !!errors.end_date && "Please set a valid time",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Controller
                    name="timezone"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <SelectTimezone
                        sx={{ width: "50%" }}
                        label="Select timezone of the shift"
                        onChange={onChange}
                        value={value}
                        error={!!errors.timezone}
                        helperText={
                          !!errors.timezone && "This field is required"
                        }
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 2 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={loadingTemplate}
                  loading={adding || updating}
                  // sx={{ width: "50%" }}
                >
                  {!!data ? "Update" : "Create"}
                </LoadingButton>
              </Stack>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ShiftTemplateEditPage;
